import applyStyle, { applyStyleOnLoad } from './applyStyle';
import computeStyle from './computeStyle';
import arrow from './arrow';
import flip from './flip';
import keepTogether from './keepTogether';
import offset from './offset';
import preventOverflow from './preventOverflow';
import shift from './shift';
import hide from './hide';
import inner from './inner';

/**
 * Modifier function, each modifier can have a function of this type assigned
 * to its `fn` property.<br />
 * These functions will be called on each update, this means that you must
 * make sure they are performant enough to avoid performance bottlenecks.
 *
 * @function ModifierFn
 * @argument {dataObject} data - The data object generated by `update` method
 * @argument {Object} options - Modifiers configuration and options
 * @returns {dataObject} The data object, properly modified
 */

/**
 * Modifiers are plugins used to alter the behavior of your poppers.<br />
 * Popper.js uses a set of 9 modifiers to provide all the basic functionalities
 * needed by the library.
 *
 * Usually you don't want to override the `order`, `fn` and `onLoad` props.
 * All the other properties are configurations that could be tweaked.
 * @namespace modifiers
 */
export default {
 /**
 * Modifier used to shift the popper on the start or end of its reference
 * element.<br />
 * It will read the variation of the `placement` property.<br />
 * It can be one either `-end` or `-start`.
 * @memberof modifiers
 * @inner
 */
 shift: {
 /** @prop {number} order=100 - Index used to define the order of execution */
 order: 100,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: shift,
 },

 /**
 * The `offset` modifier can shift your popper on both its axis.
 *
 * It accepts the following units:
 * - `px` or unitless, interpreted as pixels
 * - `%` or `%r`, percentage relative to the length of the reference element
 * - `%p`, percentage relative to the length of the popper element
 * - `vw`, CSS viewport width unit
 * - `vh`, CSS viewport height unit
 *
 * For length is intended the main axis relative to the placement of the popper.<br />
 * This means that if the placement is `top` or `bottom`, the length will be the
 * `width`. In case of `left` or `right`, it will be the height.
 *
 * You can provide a single value (as `Number` or `String`), or a pair of values
 * as `String` divided by a comma or one (or more) white spaces.<br />
 * The latter is a deprecated method because it leads to confusion and will be
 * removed in v2.<br />
 * Additionally, it accepts additions and subtractions between different units.
 * Note that multiplications and divisions aren't supported.
 *
 * Valid examples are:
 * ```
 * 10
 * '10%'
 * '10, 10'
 * '10%, 10'
 * '10 + 10%'
 * '10 - 5vh + 3%'
 * '-10px + 5vh, 5px - 6%'
 * ```
 * > **NB**: If you desire to apply offsets to your poppers in a way that may make them overlap
 * > with their reference element, unfortunately, you will have to disable the `flip` modifier.
 * > More on this [reading this issue](https://github.com/FezVrasta/popper.js/issues/373)
 *
 * @memberof modifiers
 * @inner
 */
 offset: {
 /** @prop {number} order=200 - Index used to define the order of execution */
 order: 200,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: offset,
 /** @prop {Number|String} offset=0
 * The offset value as described in the modifier description
 */
 offset: 0,
 },

 /**
 * Modifier used to prevent the popper from being positioned outside the boundary.
 *
 * An scenario exists where the reference itself is not within the boundaries.<br />
 * We can say it has "escaped the boundaries" � or just "escaped".<br />
 * In this case we need to decide whether the popper should either:
 *
 * - detach from the reference and remain "trapped" in the boundaries, or
 * - if it should ignore the boundary and "escape with its reference"
 *
 * When `escapeWithReference` is set to`true` and reference is completely
 * outside its boundaries, the popper will overflow (or completely leave)
 * the boundaries in order to remain attached to the edge of the reference.
 *
 * @memberof modifiers
 * @inner
 */
 preventOverflow: {
 /** @prop {number} order=300 - Index used to define the order of execution */
 order: 300,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: preventOverflow,
 /**
 * @prop {Array} [priority=['left','right','top','bottom']]
 * Popper will try to prevent overflow following these priorities by default,
 * then, it could overflow on the left and on top of the `boundariesElement`
 */
 priority: ['left', 'right', 'top', 'bottom'],
 /**
 * @prop {number} padding=5
 * Amount of pixel used to define a minimum distance between the boundaries
 * and the popper this makes sure the popper has always a little padding
 * between the edges of its container
 */
 padding: 5,
 /**
 * @prop {String|HTMLElement} boundariesElement='scrollParent'
 * Boundaries used by the modifier, can be `scrollParent`, `window`,
 * `viewport` or any DOM element.
 */
 boundariesElement: 'scrollParent',
 },

 /**
 * Modifier used to make sure the reference and its popper stay near eachothers
 * without leaving any gap between the two. Expecially useful when the arrow is
 * enabled and you want to assure it to point to its reference element.
 * It cares only about the first axis, you can still have poppers with margin
 * between the popper and its reference element.
 * @memberof modifiers
 * @inner
 */
 keepTogether: {
 /** @prop {number} order=400 - Index used to define the order of execution */
 order: 400,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: keepTogether,
 },

 /**
 * This modifier is used to move the `arrowElement` of the popper to make
 * sure it is positioned between the reference element and its popper element.
 * It will read the outer size of the `arrowElement` node to detect how many
 * pixels of conjuction are needed.
 *
 * It has no effect if no `arrowElement` is provided.
 * @memberof modifiers
 * @inner
 */
 arrow: {
 /** @prop {number} order=500 - Index used to define the order of execution */
 order: 500,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: arrow,
 /** @prop {String|HTMLElement} element='[x-arrow]' - Selector or node used as arrow */
 element: '[x-arrow]',
 },

 /**
 * Modifier used to flip the popper's placement when it starts to overlap its
 * reference element.
 *
 * Requires the `preventOverflow` modifier before it in order to work.
 *
 * **NOTE:** this modifier will interrupt the current update cycle and will
 * restart it if it detects the need to flip the placement.
 * @memberof modifiers
 * @inner
 */
 flip: {
 /** @prop {number} order=600 - Index used to define the order of execution */
 order: 600,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: flip,
 /**
 * @prop {String|Array} behavior='flip'
 * The behavior used to change the popper's placement. It can be one of
 * `flip`, `clockwise`, `counterclockwise` or an array with a list of valid
 * placements (with optional variations).
 */
 behavior: 'flip',
 /**
 * @prop {number} padding=5
 * The popper will flip if it hits the edges of the `boundariesElement`
 */
 padding: 5,
 /**
 * @prop {String|HTMLElement} boundariesElement='viewport'
 * The element which will define the boundaries of the popper position,
 * the popper will never be placed outside of the defined boundaries
 * (except if keepTogether is enabled)
 */
 boundariesElement: 'viewport',
 },

 /**
 * Modifier used to make the popper flow toward the inner of the reference element.
 * By default, when this modifier is disabled, the popper will be placed outside
 * the reference element.
 * @memberof modifiers
 * @inner
 */
 inner: {
 /** @prop {number} order=700 - Index used to define the order of execution */
 order: 700,
 /** @prop {Boolean} enabled=false - Whether the modifier is enabled or not */
 enabled: false,
 /** @prop {ModifierFn} */
 fn: inner,
 },

 /**
 * Modifier used to hide the popper when its reference element is outside of the
 * popper boundaries. It will set a `x-out-of-boundaries` attribute which can
 * be used to hide with a CSS selector the popper when its reference is
 * out of boundaries.
 *
 * Requires the `preventOverflow` modifier before it in order to work.
 * @memberof modifiers
 * @inner
 */
 hide: {
 /** @prop {number} order=800 - Index used to define the order of execution */
 order: 800,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: hide,
 },

 /**
 * Computes the style that will be applied to the popper element to gets
 * properly positioned.
 *
 * Note that this modifier will not touch the DOM, it just prepares the styles
 * so that `applyStyle` modifier can apply it. This separation is useful
 * in case you need to replace `applyStyle` with a custom implementation.
 *
 * This modifier has `850` as `order` value to maintain backward compatibility
 * with previous versions of Popper.js. Expect the modifiers ordering method
 * to change in future major versions of the library.
 *
 * @memberof modifiers
 * @inner
 */
 computeStyle: {
 /** @prop {number} order=850 - Index used to define the order of execution */
 order: 850,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: computeStyle,
 /**
 * @prop {Boolean} gpuAcceleration=true
 * If true, it uses the CSS 3d transformation to position the popper.
 * Otherwise, it will use the `top` and `left` properties.
 */
 gpuAcceleration: true,
 /**
 * @prop {string} [x='bottom']
 * Where to anchor the X axis (`bottom` or `top`). AKA X offset origin.
 * Change this if your popper should grow in a direction different from `bottom`
 */
 x: 'bottom',
 /**
 * @prop {string} [x='left']
 * Where to anchor the Y axis (`left` or `right`). AKA Y offset origin.
 * Change this if your popper should grow in a direction different from `right`
 */
 y: 'right',
 },

 /**
 * Applies the computed styles to the popper element.
 *
 * All the DOM manipulations are limited to this modifier. This is useful in case
 * you want to integrate Popper.js inside a framework or view library and you
 * want to delegate all the DOM manipulations to it.
 *
 * Note that if you disable this modifier, you must make sure the popper element
 * has its position set to `absolute` before Popper.js can do its work!
 *
 * Just disable this modifier and define you own to achieve the desired effect.
 *
 * @memberof modifiers
 * @inner
 */
 applyStyle: {
 /** @prop {number} order=900 - Index used to define the order of execution */
 order: 900,
 /** @prop {Boolean} enabled=true - Whether the modifier is enabled or not */
 enabled: true,
 /** @prop {ModifierFn} */
 fn: applyStyle,
 /** @prop {Function} */
 onLoad: applyStyleOnLoad,
 /**
 * @deprecated since version 1.10.0, the property moved to `computeStyle` modifier
 * @prop {Boolean} gpuAcceleration=true
 * If true, it uses the CSS 3d transformation to position the popper.
 * Otherwise, it will use the `top` and `left` properties.
 */
 gpuAcceleration: undefined,
 },
};

/**
 * The `dataObject` is an object containing all the informations used by Popper.js
 * this object get passed to modifiers and to the `onCreate` and `onUpdate` callbacks.
 * @name dataObject
 * @property {Object} data.instance The Popper.js instance
 * @property {String} data.placement Placement applied to popper
 * @property {String} data.originalPlacement Placement originally defined on init
 * @property {Boolean} data.flipped True if popper has been flipped by flip modifier
 * @property {Boolean} data.hide True if the reference element is out of boundaries, useful to know when to hide the popper.
 * @property {HTMLElement} data.arrowElement Node used as arrow by arrow modifier
 * @property {Object} data.styles Any CSS property defined here will be applied to the popper, it expects the JavaScript nomenclature (eg. `marginBottom`)
 * @property {Object} data.arrowStyles Any CSS property defined here will be applied to the popper arrow, it expects the JavaScript nomenclature (eg. `marginBottom`)
 * @property {Object} data.boundaries Offsets of the popper boundaries
 * @property {Object} data.offsets The measurements of popper, reference and arrow elements.
 * @property {Object} data.offsets.popper `top`, `left`, `width`, `height` values
 * @property {Object} data.offsets.reference `top`, `left`, `width`, `height` values
 * @property {Object} data.offsets.arrow] `top` and `left` offsets, only one of them will be different from 0
 */
